import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import { pin, pinStart } from "../icons"

import Layout from "../components/layout"
import Map from "../components/map"
import Section from "../components/section"
import Separator from "../components/separator"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const POIs = [
    {
      title: "Castillo de Moutas",
      icon: pinStart,
      lat: 43.489871764077236,
      lng: -6.113591194152833,
      link: "https://goo.gl/maps/zWV9JJbhEwRC8gim6",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipMumX_Ik_pS6zHmDkHzdRvknmCbWyPiMFIw0aNa=w426-h240-k-no",
    },
    {
      title: "Gijón",
      icon: pin,
      lat: 43.53294683737658,
      lng: -5.659608838640434,
      link: "https://goo.gl/maps/Lsk2KW8wQmB6NC8bA",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipOqam-YZ3f80Ja4XV8KjBVo7hhMTe8ff3ln8Tqs=w408-h306-k-no",
    },
    {
      title: "Oviedo",
      icon: pin,
      lat: 43.36185718013058,
      lng: -5.849355961793985,
      link: "https://goo.gl/maps/9iCCU1f54C3fV8tY8",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipMqK2j_lhA8zoTDHeuftIXhP6xQK2vi7nfqMA4M=w408-h839-k-no",
    },
    {
      title: "Avilés",
      icon: pin,
      lat: 43.55811495131814,
      lng: -5.924668790531451,
      link: "https://goo.gl/maps/HCn1VAhxoFdwsJzJ6",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipPi2sfT8YQshlfC8k_mMv7Ueg_vL6QHPcH_fQPR=w408-h305-k-no",
    },
  ]

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Puntos de interés</h2>
        <Separator />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit
          et nisi vel bibendum. Proin rutrum tortor orci. Aenean interdum
          gravida faucibus. Nullam tincidunt, quam eget sagittis auctor, turpis
          leo hendrerit diam, posuere tincidunt justo magna vel enim. Curabitur
          vitae metus id enim hendrerit maximus in at sapien. Praesent sit amet
          bibendum lorem. Sed a ipsum sit amet odio laoreet porttitor. Sed sed
          ante sodales, pharetra ipsum in, condimentum diam. Nam imperdiet diam
          ut purus viverra, nec maximus diam semper. In lacinia felis non libero
          tincidunt, dignissim dictum tellus eleifend. Duis consequat luctus
          risus, sagittis bibendum risus vulputate quis. Phasellus in nisl
          augue. Sed ullamcorper, eros quis eleifend tempus, tortor tortor
          sodales est, eu consequat nunc turpis eu turpis.
        </p>
        <p>
          Cras dapibus mi ut nisi aliquet consectetur. Mauris auctor, tortor sit
          amet auctor auctor, orci diam porttitor elit, ut sagittis nisl dolor
          non augue. Proin ut hendrerit ante, ac tincidunt risus. Aliquam
          molestie dignissim tincidunt. Nam cursus odio vel sem volutpat
          fermentum. Etiam aliquam sem at velit laoreet tincidunt. Donec
          bibendum at turpis sed egestas. Pellentesque sed eleifend ligula. Nunc
          pulvinar rhoncus mattis. Nunc id ex gravida, dictum leo sit amet,
          auctor lacus. Nulla volutpat faucibus ligula, in mollis lectus finibus
          sed. Donec est lorem, varius vitae tincidunt eu, egestas nec odio. Nam
          auctor orci at vestibulum accumsan. Etiam viverra mi et feugiat
          consequat.
        </p>
        <p>
          Nam quis sapien massa. Nunc faucibus mollis convallis. Aliquam varius
          porttitor ligula, sed mattis tellus aliquet et. Cras malesuada diam
          interdum tempus luctus. Pellentesque aliquet, risus in tincidunt
          lobortis, orci dui dictum eros, ut congue orci urna vitae tellus.
          Aliquam in accumsan enim. Quisque laoreet ullamcorper lorem, non
          lacinia mauris efficitur et. Etiam eget velit at nulla luctus aliquet
          ut ac purus.
        </p>
        <p>
          Curabitur eget leo eu dolor porta tristique ut sed arcu. Morbi turpis
          odio, vehicula sed elit at, vulputate sagittis urna. Nulla mattis
          fringilla purus, at suscipit ante rhoncus lobortis. Fusce eu eros vel
          elit euismod luctus. Curabitur pharetra sem non odio malesuada
          consectetur. Curabitur eu elit tempus, feugiat eros vel, efficitur
          lorem. Nullam tincidunt dictum faucibus. Maecenas pellentesque turpis
          a mi pretium sagittis. Phasellus in bibendum elit. Mauris dignissim,
          ante at blandit cursus, enim sem semper lacus, vel tincidunt velit
          lacus ut tellus. Vestibulum vel pretium justo.
        </p>
      </Section>

      <Map
        center={{
          lat: 43.4647896,
          lng: -5.7844736,
        }}
        zoom={10}
        POIs={POIs}
      />
    </Layout>
  )
}
